import { debug } from '/js/zonpotentie_local.js';
import { showHideControls } from "/js/zonpotentie_local.js";
import { HideControls } from "/js/zonpotentie_local.js";
import { setSelectedObject } from "/js/zonpotentie_local.js";
import { callHint } from "/js/zonpotentie_local.js";
import { DragDrop } from "/js/dragdrop.js";

class zpMap {  
  #viewer = null;
  #cesium = null;
  #pinBuilder = null;
  #dataSources = [];
  #tilesets = [];
  #pickedItems = [];
  #conditions = [];
  
  #dataSourceRoots = {
    nl3D: 'https://www.nederlandin3d.nl/viewer/datasource-data'
  };

  #config = {
    debug: false,
    target: 'cluster-viewer',
    imageryUrl: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    terrainUrl: `${this.#dataSourceRoots.nl3D}/882a9596-4d32-479a-a130-33cfbc713696`,
    tilelayers: [],
    cluster: {
      pixelRange: 50,
      minimumClusterSize: 20,
      selectedColor: 'green',
      clusterIconColor: 'darkblue',
      disableDepthTestDistance: true,
    },
    zoom: {
      zoomOnClick: true,
      zoomDistancePoint: 1000,
      zoomDistanceCluster: 5000,
      zoomDistanceStartPosition: 50000,
    },
    startPosition: 'auto',
    onClick: (id, properties) => {
      debug ('New map Onclick event: ' + id, properties);
    }
  };

  #layerConfig = {
    markerColor: 'yellow',
    markerSymbol: 'marker',
    markerSize: 48,
    clampToGround: true,
  };

  #icons = {
    'airfield': 'airfield',
    'airport': 'airport',
  };

  #merge = (target, source) => {
    const isObject = (item) => {
      return (item && typeof item === 'object' && !Array.isArray(item));
    };

    let output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
      Object.keys(source).forEach(key => {
        if (isObject(source[key])) {
          if (!(key in target))
            Object.assign(output, { [key]: source[key] });
          else
            output[key] = this.#merge(target[key], source[key]);
        } else {
          Object.assign(output, { [key]: source[key] });
        }
      });
    }
    return output;
  };

  async activate(opts) {
    this.#config = this.#merge(this.#config, opts);

    const { target } = this.#config;
    const id = target || `viewer-${Math.random()}`;
    let element = document.getElementById(id);

    debug("target div: " + element.id);

    if (!element) {
      // if target element not foud, create one
      element = document.createElement('div');
      element.id = id;
      document.body.appendChild(element);
    } else {
      null;
    }
    element.style.cssText = this.#config.style

    this.#cesium = window.Cesium;

    this.#pinBuilder = new this.#cesium.PinBuilder();
    this.#viewer = new this.#cesium.Viewer(id, {
      animation: false,
      baseLayerPicker: false,
      fullscreenButton: true,
      vrButton: false,
      geocoder: false,
      homeButton: false,
      infoBox: false,
      sceneModePicker: true,
      selectionIndicator: true,
      //SkyAtmosphere: true,
      //skyAtmosphereLightIntensity: 0,
      timeline: false,
      shadows: false,
      navigationHelpButton: false,
      navigationInstructionsInitiallyVisible: true,
      scene3DOnly: true,
      shouldAnimate: false,
      imageryProvider: new this.#cesium.UrlTemplateImageryProvider({
        url: this.#config.imageryUrl,
        maximumLevel: 19,
      }),
      terrainProvider: new this.#cesium.CesiumTerrainProvider({
        url: this.#config.terrainUrl,
      }),
    });

    
    if (this.#config.debug) {
      window.__daprviewer = this;
      this.#viewer.extend(this.#cesium.viewerCesiumInspectorMixin);
      this.#viewer.extend(this.#cesium.viewerCesium3DTilesInspectorMixin);
  
      const style = document.createElement('style');
      style.innerHTML = `
      .cesium-viewer-cesiumInspectorContainer {
        top: auto!important;
        bottom: 0!important;
        right: 340px!important;
      }
      
      .cesium-viewer-cesium3DTilesInspectorContainer {
        top: auto!important;
        bottom: 0!important;
      }
      `
      document.head.append(style);
    }
    this.#config.tilelayers.forEach((tileLayerConfig) => {
      const tileset = new this.#cesium.Cesium3DTileset(tileLayerConfig);
      tileset.maximumScreenSpaceError = 24;
      tileset.debugShowBoundingVolume = false;
      tileset.debugShowContentBoundingVolume = false;
      tileset.enableDebugWireframe = false;
      tileset.debugShowUrl = false;
      tileset.showOutline = true;
      tileset.outlineColor = Cesium.Color.fromCssColorString("#875217");
      //tileset.zIndex = 1;
      this.#tilesets.push(tileset);
      this.#viewer.scene.primitives.add(tileset);
    });

    const drag = new DragDrop(this.#viewer);
    const scene = this.#viewer.scene;
    scene.screenSpaceCameraController.enableCollisionDetection = false;

    this.#viewer.screenSpaceEventHandler.setInputAction(async ({ position }) => {
      debug('screenSpaceEventHandler.left_down (+)');
      debug('position: ' + position); 

      const pickedItem = this.#viewer.scene.pick(position);
      if (pickedItem) {
        debug(pickedItem);
      }

      if (pickedItem) {
        if (pickedItem.id) {
          debug('pickedItem.id: ' + pickedItem.id);

          const entity = pickedItem.id;
          
          setSelectedObject (pickedItem, position, entity);

          debug('propertyNames');
          var entityPropertyNames = entity.properties.propertyNames;
          debug(entityPropertyNames);
          debug('propertyValues');
          var entityValues = entity.properties.getValue('gml_id');
          debug(entityValues);
          var eType = JSON.stringify(entityValues[entityPropertyNames[0]] ); 
          var eType = entityValues[entityPropertyNames[0]]; 
          debug('entityValues[0]: ' + JSON.stringify(entityValues[entityPropertyNames[0]] ));
          debug('eType: ' + eType);

          if (eType === "userObject") {
            debug('Entity is custom object');
            if (pickedItem.id) {    
              drag.enable();
              debug (entity);
             
              const currentTime = this.#viewer.clock.currentTime;
              var scratch3dPosition = new Cesium.Cartesian3();
              var position3d = entity.position.getValue(currentTime, scratch3dPosition);
              debug("position3d: " + position3d);
              var cartographic = Cesium.Cartographic.fromCartesian(position3d);
              debug ("--> cartographic: " + cartographic);
              debug (
                'lon ' + Cesium.Math.toDegrees(cartographic.longitude) + ', ' +
                'lat ' + Cesium.Math.toDegrees(cartographic.latitude) + ', ' +
                'alt ' + cartographic.height);

              // show or hide controls based on object
              showHideControls (entity);
  
              // set Slider Values 
              sliderHeading.value = entity.customAttributes.orientation.heading;
              sliderHeadingValue.innerHTML = sliderHeading.value;
              sliderPitch.value = entity.customAttributes.orientation.pitch;
              sliderPitchValue.innerHTML = sliderPitch.value;
              sliderScale.value = entity.customAttributes.orientation.scale;
              sliderScaleValue.innerHTML = sliderScale.value;
              sliderHeight.value = entity.customAttributes.orientation.height;
              sliderHeightValue.innerHTML = sliderHeight.value;
              sliderWatt.value = entity.customAttributes.capacityWatt;
              sliderWattValue.innerHTML = sliderWatt.value;
              sliderVermogensOpbrengst.value = entity.customAttributes.vermogensopbrengst;
              sliderVermogensOpbrengst.innerHTML = sliderVermogensOpbrengst.value;

            }
          }

          if (eType === 'Marker') { 
            debug('IS marker');

            callHint("Navigatie", "Gebruik de control toets en de muis (scrollwiel) om in te zoomen en te navigeren of gebruik twee vingers op de tablet", "img/2D/muis.png");

            drag.disable();
            entity.__billboard = this.#cesium.clone(entity.billboard);
            entity.billboard.color = this.#cesium.Color.fromCssColorString(this.#config.cluster.selectedColor)
            if (this.#config.zoom.zoomOnClick) {
              debug('this.#config.zoom.zoomDistancePoint: ' + this.#config.zoom.zoomDistancePoint);
              this.#viewer.scene.screenSpaceCameraController.minimumZoomDistance = this.#config.zoom.zoomDistancePoint;
              this.#viewer.flyTo(entity, { duration: 1 }).then(() => {
                this.#viewer.scene.screenSpaceCameraController.minimumZoomDistance = 1;
              });
            }
          
            if (this.#dataSources.some((ds) => ds.entities.contains(entity))) {
              debug('Click on the marker');
              const props = entity.properties;
              const result = props.propertyNames.reduce((p, c) => ({
                ...p,
                [c]: props[c].getValue(),
              }), {});
              
              this.#pickedItems.push(entity);

              this.#config.onClick(entity.id, result);
            }
          } 

        } else if (pickedItem.primitive && pickedItem.primitive.isCesium3DTileset) {
          debug('Click on on Tileset object');

          HideControls ();

          pickedItem.description =
                '<table class="cesium-infoBox-defaultTable"><tbody>' +
                '<tr><th>Building ID</th><td>' + pickedItem.getProperty('gid') + '</td></tr>' +
        				'<tr><th>Building Identification</th><td>' + pickedItem.getProperty('identificatie') + '</td></tr>'  +
               	'</tbody></table>';

          debug('gid: ' + pickedItem.getProperty('gid'));

          debug('geoservice_api.php');
          let json;
          await fetch('https://zonpotentie.nl/api/geoservice_api.php?gid=' + pickedItem.getProperty('gid'), {
            method: 'get'
          })
          .then(response => response.json())
          .then(jsonData => json = jsonData)
          .catch(err => {
            //error block
            debug('drawGeo no result')
          }
          )
           
          debug('geoservice_api.php, result: ');  
          debug (json);
          if (json) {
            
            var objectViewer = document.getElementById("objectViewer");
            objectViewer.style.display = 'block';

            drawGeo(json, json.vertices, 10, 'plane', {
              color: 'blue'
            })
          }

          debug('bag_pand_api.php');
          let jsonBAGPand;
          await fetch('https://www.zonpotentie.nl/api/bag_pand_api.php?gid=' + pickedItem.getProperty('gid'), {
            method: 'get'
          })
          .then(response => response.text())
          .then(jsonData => jsonBAGPand = jsonData)
          .catch(err => {
            //error block
            debug('call_api no result: ' + err)
          }
          )

          if (jsonBAGPand) {
            jsonBAGPand = JSON.parse(jsonBAGPand);
            debug ('bag_pand_api.php, result: ');
            debug (jsonBAGPand);

            let infoHTML = '<div id="objectInfo"> '+ 
                           '<p>Type verblijfsobject: ' + jsonBAGPand.pand.identificatie + '</p>' +
                           '<p>Domein: ' + jsonBAGPand.pand.domein + '</p>' +
                           '<p>Oorspronkelijk bouwjaar: ' + jsonBAGPand.pand.oorspronkelijkBouwjaar + '</p>' +
                           '<p>Status: ' + jsonBAGPand.pand.status + '</p>' +
                           '</<div>'
            ;

            document.getElementById('objectInformation').innerHTML=infoHTML;
          }

          /*
          debug('bag_pandvbo_api.php');
          let jsonBAGPandVBO;
          await fetch('https://www.zonpotentie.nl/api/bag_pandvbo_api.php?gid=' + pickedItem.getProperty('gid'), {
            method: 'get'
          })
          .then(response => response.text())
          .then(jsonData => jsonBAGPandVBO = jsonData)
          .catch(err => {
            //error block
            debug('call_api no result: ' + err)
          }
          )

          if (jsonBAGPandVBO) {
            jsonBAGPand = JSON.parse(jsonBAGPandVBO);
            debug ('bag_pandpand_api.php, result: ');
            debug (jsonBAGPandVBO);
          
          }
          */

        } else {
          debug('else');
          if (this.#config.zoom.zoomOnClick) {
            this.#viewer.entities.removeById('fake');
            const fakeEntity = this.#viewer.entities.add(new this.#cesium.Entity({
              id: 'fake',
              position: pickedItem.primitive.position,
              show: true,
              point: new this.#cesium.PointGraphics(),
            }));
            this.#viewer.scene.screenSpaceCameraController.minimumZoomDistance = this.#config.zoom.zoomDistanceCluster;
            this.#viewer.flyTo(fakeEntity, { duration: 1 }).then(() => {
              this.#viewer.scene.screenSpaceCameraController.minimumZoomDistance = 1;
            });
          }

          this.#config.onClick(null, {});
        }
      } else {
        debug('Click on the map imagery map (not on object)');
        this.#config.onClick(null, {});
      }
      debug('screenSpaceEventHandler.left_down (-)');
    }, this.#cesium.ScreenSpaceEventType.LEFT_DOWN); 
  }
  
  async addLayer(name, idProperty, data, config) {
    
    debug('addLayer (+)');

    const { dataSources } = this.#viewer;

    // conditially set style. Currently not applicable
    data.features.forEach((feature) => {
      this.#conditions.unshift([
        "${gid} === " + `'${feature.properties[idProperty]}'`,
        "color('red')",
      ]);
    });
    const style = new this.#cesium.Cesium3DTileStyle({
      color: { conditions: this.#conditions }
    });
    this.#tilesets.forEach((tileset) => {
      tileset.style = style
    });

    const layerConfig = this.#merge(this.#layerConfig, config);
    debug('layerConfig: ' + JSON.stringify(layerConfig)); 

    if (typeof layerConfig.markerColor === 'string')
      layerConfig.markerColor = this.#cesium.Color.fromCssColorString(layerConfig.markerColor);

    debug('layerConfig: ' + JSON.stringify(layerConfig));  
    debug('data: ' + JSON.stringify(data));
    const dataSource = await dataSources.add(this.#cesium.GeoJsonDataSource.load(data, layerConfig));

    dataSource.entities.values.forEach((entity) => {
      debug('entity: ' + entity.id);
      debug('this.#config.cluster.disableDepthTestDistance: ' + this.#config.cluster.disableDepthTestDistance);
      if (this.#config.cluster.disableDepthTestDistance) {
        if (entity.billboard) entity.billboard.disableDepthTestDistance = 0;
      }
    });
    dataSource.name = name;
    dataSource.clustering.enabled = true;
    dataSource.clustering.pixelRange = this.#config.cluster.pixelRange;
    dataSource.clustering.minimumClusterSize = this.#config.cluster.minimumClusterSize;

    debug('dataSource.name: '+ dataSource.name);
    debug('dataSource.clustering.enabled: ' + dataSource.clustering.enabled);
    debug('dataSource.clustering.pixelRange: ' + dataSource.clustering.pixelRange);
    debug('dataSource.clustering.minimumClusterSize: ' + dataSource.clustering.minimumClusterSize); 
    
    dataSource.clustering.clusterEvent.addEventListener((clusteredEntities, cluster) => {
      debug('clustering (+)');
      const { label, billboard } = cluster;
      label.show = false;

      billboard.show = true;
      billboard.verticalOrigin = this.#cesium.VerticalOrigin.BOTTOM;
      billboard.heightReference = this.#cesium.HeightReference.CLAMP_TO_GROUND;
      if (this.#config.cluster.disableDepthTestDistance) {
        billboard.disableDepthTestDistance = 0;
      }

      billboard.image = this.#pinBuilder
        .fromText(
          clusteredEntities.length.toString(),
          this.#cesium.Color.fromCssColorString(this.#config.cluster.clusterIconColor),
          48,
        )
        .toDataURL();
        debug('clustering (-)');  
    });
    
    debug('startPosition (+)');
    if (this.#config.startPosition === 'auto') {
      debug('startPosition auto (+)');
      debug('this.#config.zoom.zoomDistanceStartPosition: ' + this.#config.zoom.zoomDistanceStartPosition);
      debug('this.#cesium.Math.toRadians(0): ' + this.#cesium.Math.toRadians(0));
      debug('this.#cesium.Math.toRadians(-45): ' + this.#cesium.Math.toRadians(-45));
      
      this.#viewer.zoomTo(dataSource, new this.#cesium.HeadingPitchRange(
        this.#cesium.Math.toRadians(0),
        this.#cesium.Math.toRadians(-45),
        this.#config.zoom.zoomDistanceStartPosition,
      )
      );

      debug('startPosition auto (-)');
      ;
    } else {
      debug('startPosition NIET auto (+)');
      const { lat, lng, heading, pitch, range, } = this.#config.startPosition;
      const center = this.#cesium.Cartesian3.fromDegrees(lng, lat);
      const transform = this.#cesium.Transforms.eastNorthUpToFixedFrame(center);
      this.#viewer.camera.lookAtTransform(transform, new this.#cesium.HeadingPitchRange(
        this.#cesium.Math.toRadians(heading),
        this.#cesium.Math.toRadians(pitch),
        range,
      ));
      this.#viewer.camera._setTransform(this.#cesium.Matrix4.IDENTITY);
      debug('startPosition NIET auto (-)');
    }
    debug('startPosition (-)');

    this.#dataSources.push(dataSource);

    debug('addLayer (-)');
    return dataSource;
  }

  async removeLayer(layer) {
    return new Promise((resolve) => {
      const { dataSources } = this.#viewer;
      dataSources.remove(layer);
      resolve();
    });
  }

  async addWmsLayer(url, layers, version = '1.3.0', format = 'image/png') {
    const provider = new this.#cesium.WebMapServiceImageryProvider({
      url,
      layers,
      parameters: {
        transparent: true,
        format,
        version,
      },
      enablePickFeatures: false,
      getFeatureInfoParameters: {
        version,
      },
      [version === '1.3.0' ? 'crs' : 'srs']: 'CRS:84',
    });
    return new Promise((resolve) => resolve(this.#viewer.imageryLayers.addImageryProvider(provider)));
  }

  async removeWmsLayer(layer) {
    this.#viewer.imageryLayers.remove(layer);
    return new Promise((resolve) => resolve(true));
  }

  async deselectItem(id) {
    return new Promise((resolve) => {
      const entity = this.#pickedItems.find((x) => x.id === id);
      if (entity) {
        entity.billboard = this.#cesium.clone(entity.__billboard);
        delete entity.__billboard;
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }

  get config() {
    return this.#config;
  }

  get layerConfig() {
    return this.#layerConfig
  }

  get icons() {
    return this.#icons;
  }

  get viewer() {
    return this.#viewer;
  }

};

export { zpMap};
